import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "email", "phone", "select" ]

  connect() {
    this.select(this.selectTarget.value);
  }

  selectField(event) {
    this.select(event.target.value);
  }

  select(element) {
    switch (element) {
      case 'channel_email':
        this.showEmail();
        break
      case 'channel_phone':
        this.showPhone();
        break
      default:
        this.resetFields();
        break
    }
  }

  showEmail() {
    this.emailTarget.classList.remove("hidden");
    this.emailTarget.querySelector("input").disabled = false
    this.phoneTarget.classList.add("hidden");
    this.phoneTarget.querySelector("input").disabled = true
  }

  showPhone() {
    this.phoneTarget.classList.remove("hidden");
    this.emailTarget.querySelector("input").disabled = true
    this.emailTarget.classList.add("hidden");
    this.phoneTarget.querySelector("input").disabled = false
  }

  resetFields() {
    this.phoneTarget.classList.add("hidden");
    this.emailTarget.querySelector("input").disabled = true
    this.emailTarget.classList.add("hidden");
    this.phoneTarget.querySelector("input").disabled = true
  }
}
