import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "datalist", "hiddenInput"];

  connect() {
    if (this.hiddenInputTarget.value) {
      const selectedOption = this.datalistTarget.querySelector(
        `option[data-value="${this.hiddenInputTarget.value}"]`,
      );
      if (selectedOption) {
        this.inputTarget.value = selectedOption.value;
      }
    }
  }

  updateValue() {
    const trimmedValue = this.inputTarget.value.trim();
    const selectedOption = this.datalistTarget.querySelector(
      `option[value="${trimmedValue}"]`,
    );
    if (selectedOption) {
      this.hiddenInputTarget.value = selectedOption.dataset.value;
    }
  }

  // Validate and reset if invalid when the user leaves the input
  blur() {
    const trimmedValue = this.inputTarget.value.trim();
    const selectedOption = this.datalistTarget.querySelector(
      `option[value="${trimmedValue}"]`,
    );

    if (!selectedOption) {
      this.inputTarget.value = "";
      this.hiddenInputTarget.value = "0";
    }
  }
}
